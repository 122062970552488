import axios from "axios";

export let dateParsed = new Date().toUTCString();
dateParsed = dateParsed.split(" ").slice(1, 4).join(" ");
export function parseDateToArchiveFormat(d) {
	return d.slice(d.indexOf(" ") + 1);
}
export function parseDateToURL(d) {
	return parseDateToArchiveFormat(d).replace(" ", "-");
}
export function parseURLToDate(u) {
	return u.replace("-", " ");
}

export const url = "/api";
export const url2 = "/others";
export const url3 = "/punchvenegro";
export const url4 = "/others/edit";

export const bannerHead = "Greetings";
export const bannerBody1 = "";
export const bannerBody2 = "";
export const bannerSpan = "With Love From Hatay";
export const homePageTitle = "With love from 31";
export const instagram = "https://www.instagram.com/cemsubasi/";
export const github = "https://www.github.com/cemsubasi/";
export const headerTitle = "3hree1ne";
export const secret = "asdfjj";
export const aboutText =
	"Merhaba, ben Cemil Cem Subaşı. Bulgaristan UHT'de ingilizce eğitimi aldım. Ardından Ege Üniversitesi Bilgisayar Programcılığı Bölümünü okudum. Kendimi web alanında geliştiriyorum.";
export const homeAboutHead = "About";
export const homeAboutBody =
	"Bu blog bildiklerimi paylaşmanın yanında programlama becerilerimi pratiğe dökme amacıyla geliştirilmektedir. Keyifli okumalar dilerim.";

const instance = axios.create({
	baseURL:
		process.env.NODE_ENV === "production"
			? "https://be.localx.host"
			: "http://localhost:9003",
	timeout: 10000,
	withCredentials: "true",
	headers: { "content-type": "application/json" },
});

export const axiosCall = (method, URL, postArg) => {
	return new Promise((resolve, reject) => {
		instance[method](URL, postArg)
			.then((res) => resolve(res.data))
			.catch((err) => reject(err));
	});
};

export const Data = {
	postState: [],
	photoState: [],
	editState: "",
	errState: -1,
	isAdmin: false,
	errMessage: "",
	superInputState: "post",
};
