const Footer = () => {
  return (
    <div className="container">
      <div className="blog-footer">
        <p>
          {/*eslint-disable-next-line*/}
          <a href="#">Back to top</a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
